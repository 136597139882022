import { graphql } from "gatsby"
import React from "react"

import Layout from "../layouts"
import Container from "../components/container"
import { rhythm } from "../utils/typography"
import constants from "../utils/constants"

const PageTemplate = ({ data }) => (
  <Layout>
    <div
      css={{
        background: constants.paleYellow,
        padding: rhythm(1.5),
        paddingTop: 0,
        marginBottom: rhythm(3),
      }}
    >
      <Container>
        <h1>{data.page.edges[0].node.title}</h1>
        <div dangerouslySetInnerHTML={{ __html: data.page.edges[0].node.body.processed}} />
      </Container>
    </div>
  </Layout>
)
export default PageTemplate
export const query = graphql`
  query ($slug: String!) {
    page: allNodePage(filter: {fields: {slug: {eq: $slug}}}) {
      edges {
        node {
          title
          body {
            processed
          }
        }
      }
    }
  }
`